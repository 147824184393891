import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage } from '../../../util/reactIntl';

import { NamedLink } from '../../../components';

import css from './SectionSell.module.css';

const SectionSell = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <h2 className={css.title}>
        <FormattedMessage id="SectionSell.title" />
      </h2>
      <p className={css.description}>
        <FormattedMessage id="SectionSell.description" />
      </p>
      <NamedLink name="SetNewListingTypePage" className={css.createListingLink}>
        <FormattedMessage id="SectionSell.button" />
      </NamedLink>
    </div>
  );
};

SectionSell.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionSell.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionSell;

import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import config from '../../config';
import { getQuickSearchMakeModelPromise } from '../../ducks/AirplanesData.duck.js';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck.js';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import { $airplanesMetadataField } from '../../modules/airplanes/airplanes.selectors';
import { greatGoodDealsService } from '../../services/services';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routing/routeConfiguration';
import {
  $featuredListingsState,
  resetState as resetFeaturedListingsState,
} from '../../ducks/FeaturedListings.ducs';

import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
} from '../../components';
import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer';
import facebookImage from '../../assets/flyingFacebook-1200x630.jpeg';
import twitterImage from '../../assets/flyingTwitter-600x314.jpeg';
import SectionHero from './SectionHero/SectionHero';
import SectionPriceChange from './SectionPriceChange/SectionPriceChange.js';
import SectionSearchByTailNumber from './SectionSearchByTailNumber/SectionSearchByTailNumber';
import SectionSell from './SectionSell/SectionSell';
// import SectionAssistance from './SectionAssistance/SectionAssistance';
import SectionTrustSignals from './SectionTrustSignals/SectionTrustSignals';
import SectionGoodDealListings from './SectionGoodDealListings/SectionGoodDealListings';
import SectionFindThePlanePrice from '../../components/SectionFindThePlanPrice/SectionFindThePlanePrice';
import NewestListings from '../NewestListings/NewestListings';
import SectionSponsoredListings from './SectionSponsoredListings/SectionSponsoredListings';

import css from './LandingPage.module.scss';
import SectionNewestREListings from './SectionNewestREListings/SectionNewestREListings';

const MODAL_BREAKPOINT = 768;

export const LandingPageComponent = props => {
  const {
    newestREListings,
    airplanesMetadata,
    history,
    intl,
    location,
    scrollingDisabled,
    sponsoredListingsState,
    currentUser,
    goodDealListings,
    onManageDisableScrolling,
    changePriceListings,
    // onFetchGoodDealListings,
    onGetMakeModelPromise,
    onResetFeaturedListingsState,
  } = props;

  React.useEffect(() => {
    // TODO: Hide PlanePrice label
    // onFetchGoodDealListings();

    return () => {
      onResetFeaturedListingsState();
    };
  }, []);

  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescription' });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;
  const isWindowDefined = typeof window !== 'undefined';
  const isMobileLayout = isWindowDefined && window.innerWidth < MODAL_BREAKPOINT;

  const handleLearnMoreClick = () => {
    history.push(createResourceLocatorString(
      'PlanePriceLandingPage',
      routeConfiguration(),
      {},
      {}
    ));
  };

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar className={css.topbar}>
          <TopbarContainer
            transparentBg
            currentPage="LandingPage"
            isVisibleSearchForm={false}
          />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={css.heroContainer}>
            <SectionHero
              rootClassName={css.heroRoot}
              className={css.hero}
              history={history}
              location={location}
              onGetMakeModelPromise={onGetMakeModelPromise}
            />
          </div>
          <ul className={css.sections}>
            <li className={css.section}>
              <div className={css.sectionContent}>
                <SectionTrustSignals />
              </div>
            </li>
            {/* <li className={css.section}>
              <div className={css.sectionContent}>
                <SectionRealestateSearches />
              </div>
            </li> */}
            {!!sponsoredListingsState.data.length && (
              <li className={css.section}>
                <div className={css.sectionContent}>
                  <SectionSponsoredListings
                    intl={intl}
                    isMobile={isMobileLayout}
                    airplanesMetadata={airplanesMetadata}
                    listings={sponsoredListingsState.data}
                    currentUser={currentUser}
                    onManageDisableScrolling={onManageDisableScrolling}
                  />
                </div>
              </li>
            )}
            <li className={css.section}>
              <div className={css.sectionContent}>
                <NewestListings />
              </div>
            </li>
            {!!goodDealListings.length && (
              <li className={css.section}>
                <div className={css.sectionContent}>
                  <SectionGoodDealListings
                    airplanesMetadata={airplanesMetadata}
                    goodDealListings={goodDealListings}
                    currentUser={currentUser}
                    onManageDisableScrolling={onManageDisableScrolling}
                  />
                </div>
              </li>
            )}
            {!!changePriceListings.length &&
              <li className={css.section}>
                <div className={css.sectionContent}>
                  <SectionPriceChange
                    airplanesMetadata={airplanesMetadata}
                    changePriceListings={changePriceListings}
                    currentUser={currentUser}
                    onManageDisableScrolling={onManageDisableScrolling}
                  />
                </div>
              </li>
            }

            {!!newestREListings.length &&
              <li className={css.section}>
                <div className={css.sectionContent}>
                  <SectionNewestREListings
                    airplanesMetadata={airplanesMetadata}
                    listings={newestREListings}
                    currentUser={currentUser}
                    onManageDisableScrolling={onManageDisableScrolling}
                  />
                </div>
              </li>
            }

            <li className={css.section}>
              <div className={css.sectionContent}>
                <SectionSell />
              </div>
            </li>
            {/*<li className={css.section}>*/}
            {/*  <div className={css.sectionContent}>*/}
            {/*    <SectionFindThePlanePrice*/}
            {/*      intl={intl}*/}
            {/*      onLearnMoreClick={handleLearnMoreClick}*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*</li>*/}
            <li className={css.section}>
              <div>
                <SectionSearchByTailNumber
                  history={history}
                  intl={intl}
                />
              </div>
            </li>
          </ul>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

const { bool, object } = PropTypes;

LandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    changePriceListingsIds,
    newestREListingIds,
  } = state.LandingPage;

  const { currentUser } = state.user;
  const changePriceListingsRefs = changePriceListingsIds.map(id => ({ type: 'listing', id }));
  const newestREListingRefs = newestREListingIds.map(id => ({ type: 'listing', id }));

  return {
    changePriceListings: getMarketplaceEntities(state, changePriceListingsRefs),
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
    airplanesMetadata: $airplanesMetadataField(state),
    goodDealListings: greatGoodDealsService.$getGreatGoodDeals(state),
    sponsoredListingsState: $featuredListingsState(state),
    newestREListings: getMarketplaceEntities(state, newestREListingRefs),
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onFetchGoodDealListings: () => dispatch(greatGoodDealsService.fetchGreatGoodDealListings()),
  onGetMakeModelPromise: inputValue => dispatch(getQuickSearchMakeModelPromise(inputValue)),
  onResetFeaturedListingsState: () => dispatch(resetFeaturedListingsState()),
});

const LandingPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(LandingPageComponent);

export default LandingPage;







import React from 'react';

import { cardRenderSizes } from '../../../util/listingCard';

import { ListingCard } from '../../../components';

import css from './SectionSponsoredListings.module.scss';

const MobileListings = (props) => {
  const {
    metadata,
    listings,
    currentUser,
    onManageDisableScrolling,
  } = props;

  return (
    <div className={css.mobileListingsWrapper}>
      <div
        className={css.mobileListings}
        style={{
          width: listings.length * 230,
        }}
      >
        {listings.map(l => (
          <ListingCard
            airplaneMetadata={metadata.value[l.id.uuid]}
            className={css.listingCard}
            key={l.id.uuid}
            listing={l}
            renderSizes={cardRenderSizes(true)}
            currentUser={currentUser}
            onManageDisableScrolling={onManageDisableScrolling}
          />
        ))}
      </div>
    </div>
  );
};

export default MobileListings;

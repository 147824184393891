import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import support from './images/support.svg';
import handshake from './images/handshake.svg';
import credit from './images/credit.svg';
import worldTour from './images/world-tour.svg';

import css from './SectionTrustSignals.module.scss';

const SectionTrustSignals = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.sectionWrapper}>
        <div className={css.sectionItem}>
          <img src={worldTour} alt="worldTour" />
          1,000's of Aircraft For Sale
        </div>
        <div className={css.sectionItem}>
          <img src={support} alt="support" />
          24/7 Customer Support
        </div>
        <div className={css.sectionItem}>
          <img src={credit} alt="financing" />
          Easy Financing Available
        </div>
        <div className={css.sectionItem}>
          <img src={handshake} alt="handshake" />
          <div>
            Brought to you by{' '}
            <a target="_blank" href="https://www.flyingmag.com">
              FLYING
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

SectionTrustSignals.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionTrustSignals.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionTrustSignals;

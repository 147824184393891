import React from 'react';
import classnames from 'classnames';
import { Form as FinalForm } from 'react-final-form';

import {
  FILTER_KEY_HOME_TYPE,
  HOME_TYPE_KEY_SINGLE_FAMILY,
  HOME_TYPE_KEY_CONDO,
  HOME_TYPE_KEY_TOWNHOUSE,
  HOME_TYPE_KEY_MULTI_FAMILY,
  HOME_TYPE_KEY_APARTMENT,
  HOME_TYPE_KEY_MOBILE,
  HOME_TYPE_KEY_COOP,
  HOME_TYPE_KEY_HANGAR,
  HOME_TYPE_KEY_LAND,
  HOME_TYPE_KEY_FARM,
} from '../SearchPage/SearchPage.constants';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routing/routeConfiguration';
import { FormattedMessage } from '../../util/reactIntl';

import {
  Form,
  PrimaryButton,
  // FieldTextInput,
} from '../../components';
import HomeIcon from './icons/HomeIcon';
import HangarIcon from './icons/HangarIcon';
import LandIcon from './icons/LandIcon';
import AllPropertiesIcon from './icons/AllPropertiesIcon';
import IconSearchDesktop from '../../components/Topbar/TopbarSearchForm/IconSearchDesktop';
import LocationSearchField from '../../components/LocationSearchField/LocationSearchField';

import css from './LandingSearchForm.module.scss';

export const RealEstateLandingSearchForm = (props) => {
  const {
    intl,
    isMobileLayout,
    history,
  } = props;

  const redirectToSearchPage = searchParams => {
    history.push(createResourceLocatorString(
      'RealEstateSearchPage',
      routeConfiguration(),
      {},
      searchParams
    ));
  };

  const handleHomeClick = () => {
    redirectToSearchPage({
      [FILTER_KEY_HOME_TYPE]: [
        HOME_TYPE_KEY_SINGLE_FAMILY,
        HOME_TYPE_KEY_CONDO,
        HOME_TYPE_KEY_TOWNHOUSE,
        HOME_TYPE_KEY_MULTI_FAMILY,
        HOME_TYPE_KEY_APARTMENT,
        HOME_TYPE_KEY_MOBILE,
        HOME_TYPE_KEY_COOP,
      ].toString(),
    });
  };

  const handleHangarClick = () => {
    redirectToSearchPage({
      [FILTER_KEY_HOME_TYPE]: [ HOME_TYPE_KEY_HANGAR ].toString(),
    });
  };

  const handleLandClick = () => {
    redirectToSearchPage({
      [FILTER_KEY_HOME_TYPE]: [ HOME_TYPE_KEY_LAND, HOME_TYPE_KEY_FARM ].toString(),
    });
  };

  const handleAllClick = () => {
    redirectToSearchPage({ });
  };

  const handleSubmitForm = values => {
    const searchParams = {
      keywords: values.keywords,
    };

    if (!Object.keys(searchParams).length) {
      return ;
    }

    redirectToSearchPage(searchParams);
  };

  const onLocationChange = location => {
    if (location.selectedPlace) {
      const { search, selectedPlace } = location;
      const { origin, bounds } = selectedPlace;
      history.push(createResourceLocatorString(
        'RealEstateSearchPage',
        routeConfiguration(),
        {},
        {
          bounds,
          // origin,
          address: search,
        }
      ));
    }
  };

  const initialValues = {
    keyword: null,
  };

  return (
    <>
      <div className={css.categoriesWrapper}>
        <div className={css.firstRow}>
          <div
            className={classnames(css.categoryItem, css.reCategoryItem)}
            onClick={handleHomeClick}
          >
            <HomeIcon
              isMobile={isMobileLayout}
              className={css.icon}
            />
            <FormattedMessage id='RealEstateLandingSearchForm.category.home' />
          </div>
          <div
            className={classnames(css.categoryItem, css.reCategoryItem)}
            onClick={handleHangarClick}
          >
            <HangarIcon
              isMobile={isMobileLayout}
              className={css.icon}
            />
            <FormattedMessage id='RealEstateLandingSearchForm.category.hangar' />
          </div>
        </div>
        <div className={css.secondRow}>
          <div
            className={classnames(css.categoryItem, css.reCategoryItem)}
            onClick={handleLandClick}
          >
            <LandIcon
              isMobile={isMobileLayout}
              className={css.icon}
            />
            <FormattedMessage id='RealEstateLandingSearchForm.category.land' />
          </div>
          <div
            className={classnames(css.categoryItem, css.reCategoryItem)}
            onClick={handleAllClick}
          >
            <AllPropertiesIcon
              isMobile={isMobileLayout}
              className={css.icon}
            />
            <FormattedMessage id='RealEstateLandingSearchForm.category.all' />
          </div>
        </div>
      </div>

      <div className={css.orDelimiter}>{intl.formatMessage({ id: 'LandingSearchForm.or' })}</div>

      <FinalForm
        onSubmit={handleSubmitForm}
        initialValues={initialValues}
        render={formRenderProps => {
          const {
            handleSubmit,
          } = formRenderProps;

          return (
            <Form
              className={css.form}
              onSubmit={handleSubmit}
              autoComplete='off'
            >
              <div className={css.field}>
                <LocationSearchField
                  intl={intl}
                  rootClassName={css.locationRootClassName}
                  rootInputClassName={css.locationField}
                  isMobile={isMobileLayout}
                  placeholder={intl.formatMessage({ id: 'RealEstateLandingSearchForm.placeholder' })}
                  onLocationChange={onLocationChange}
                />
              </div>
              {/*<div className={css.locationField}>*/}

              {/*</div>*/}
              {/*<FieldTextInput*/}
              {/*  name='keywords'*/}
              {/*  id='keywords'*/}
              {/*  className={css.reSearchField}*/}
              {/*  placeholder={intl.formatMessage({ id: 'RealEstateLandingSearchForm.placeholder' })}*/}
              {/*/>*/}
              <IconSearchDesktop
                className={css.reSearchIcon}
                colorClassName={css.searchIconColor}
              />

              {/*<PrimaryButton*/}
              {/*  className={css.submitButton}*/}
              {/*>*/}
              {/*  {intl.formatMessage({ id: 'RealEstateLandingSearchForm.searchButton' })}*/}
              {/*</PrimaryButton>*/}
            </Form>
          );
        }}
      />
    </>
  );
};

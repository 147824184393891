import React from 'react';

const AllPropertiesIcon = (props) => {
  const {
    isMobile,
    className,
  } = props;

  if (isMobile) {
    return (
      <svg width="30" height="20" viewBox="0 0 30 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1_80438)">
          <path className={className} fillRule="evenodd" clipRule="evenodd" d="M5.67188 7.49005L10.9237 4.63345L16.1759 7.49005H10.9237H5.67188ZM5.67188 7.49007H16.1781V12.9996H5.67188V7.49007Z" fill="#B6B6B6"/>
          <path className={className} d="M10.877 3.00543L10.4531 3.69698L17.3284 7.43622L17.7522 6.74468L10.877 3.00543Z" fill="#B6B6B6"/>
          <path className={className} d="M4.42384 7.43076L11.2988 3.69134L10.8754 3L4 6.73942" fill="#B6B6B6"/>
          <path d="M12.0946 9.00076H9.75V13H12.0946V9.00076Z" fill="white"/>
          <path className={className} d="M15.8522 3.28134H14.4102V5.73H15.8522V3.28134Z" fill="#B6B6B6"/>
        </g>
        <g clipPath="url(#clip1_1_80438)">
          <path className={className} fillRule="evenodd" clipRule="evenodd" d="M14.4219 10.8957L19.6737 7.86055L24.9259 10.8957H19.6737H14.4219ZM14.4219 10.8957H24.9281V16.7496H14.4219V10.8957Z" fill="#B6B6B6"/>
          <path className={className} d="M19.627 6.13076L19.2031 6.86552L26.0784 10.8385L26.5022 10.1037L19.627 6.13076Z" fill="#B6B6B6"/>
          <path className={className} d="M13.1738 10.8327L20.0488 6.85955L19.6254 6.125L12.75 10.0981" fill="#B6B6B6"/>
          <path d="M20.8446 12.5008H18.5V16.75H20.8446V12.5008Z" fill="white"/>
          <path className={className} d="M24.6022 6.42393H23.1602V9.02564H24.6022V6.42393Z" fill="#B6B6B6"/>
        </g>
      </svg>
    );
  }

  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1_48665)">
        <path className={className} fillRule="evenodd" clipRule="evenodd" d="M4.67578 16.1841L13.0787 11.6135L21.4822 16.1841H21.4857V24.9994H4.67578V16.1841Z" fill="#B6B6B6"/>
        <path className={className} d="M13.0024 9.00867L12.3242 10.1151L23.3246 16.0979L24.0027 14.9915L13.0024 9.00867Z" fill="#B6B6B6"/>
        <path className={className} d="M2.67815 16.0892L13.6781 10.1061L13.0006 9L2 14.9831" fill="#B6B6B6"/>
        <path d="M14.9505 18.6012H11.1992V25H14.9505V18.6012Z" fill="white"/>
        <path className={className} d="M20.9635 9.45015H18.6562V13.368H20.9635V9.45015Z" fill="#B6B6B6"/>
      </g>
      <g clipPath="url(#clip1_1_48665)">
        <path className={className} fillRule="evenodd" clipRule="evenodd" d="M18.6758 21.6331L27.0787 16.7769L35.4822 21.6331H27.0787H18.6758ZM18.6758 21.6331H35.4857V30.9994H18.6758V21.6331Z" fill="#B6B6B6"/>
        <path className={className} d="M27.0024 14.0092L26.3242 15.1848L37.3246 21.5416L38.0027 20.3659L27.0024 14.0092Z" fill="#B6B6B6"/>
        <path className={className} d="M16.6781 21.5323L27.6781 15.1753L27.0006 14L16 20.357" fill="#B6B6B6"/>
        <path d="M28.9505 24.2013H25.1992V31H28.9505V24.2013Z" fill="white"/>
        <path className={className} d="M34.9635 14.4783H32.6562V18.641H34.9635V14.4783Z" fill="#B6B6B6"/>
      </g>
    </svg>
  );
};

AllPropertiesIcon.defaultProps = {
  className: '',
};

export default AllPropertiesIcon;

import React from 'react';

const HomeIcon = (props) => {
  const {
    isMobile,
    className,
  } = props;

  if (isMobile) {
    return (
      <svg width="30" height="20" viewBox="0 0 30 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1_80365)">
          <path className={className} fillRule="evenodd" clipRule="evenodd" d="M7.30859 9.73512L14.5656 5.45023L21.8232 9.73512H21.8262V17.9995H7.30859V9.73512Z" fill="#242424"/>
          <path className={className} d="M14.4997 3.00817L13.9141 4.04549L23.4144 9.65436L24.0001 8.61704L14.4997 3.00817Z" fill="#242424"/>
          <path className={className} d="M5.58567 9.64614L15.0857 4.03701L14.5005 3L5 8.60913" fill="#242424"/>
          <path d="M16.189 12.0011H12.9492V18H16.189V12.0011Z" fill="white"/>
          <path className={className} d="M21.3754 3.42198H19.3828V7.09497H21.3754V3.42198Z" fill="#242424"/>
        </g>
      </svg>
    );
  }

  return (
    <svg width="52" height="40" viewBox="0 0 52 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1_48595)">
        <path className={className} d="M14.6445 19.3272L26.103 12.757L37.5623 19.3272H37.5671V31.9992H14.6445V19.3272Z" fill="#242424"/>
        <path className={className} d="M26.0029 9.01252L25.0781 10.6031L40.0786 19.2033L41.0034 17.6128L26.0029 9.01252Z" fill="#242424"/>
        <path className={className} d="M11.9247 19.1908L26.9247 10.5901L26.0008 9L11 17.6007" fill="#242424"/>
        <path d="M28.6662 22.8018H23.5508V32H28.6662V22.8018Z" fill="white"/>
        <path className={className} d="M36.8533 9.64705H33.707V15.279H36.8533V9.64705Z" fill="#242424"/>
      </g>
    </svg>
  );
};

HomeIcon.defaultProps = {
  className: '',
};

export default HomeIcon;

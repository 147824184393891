export const makeRegistrationNumber = value => {
  if (!value) {
    return null;
  }
  let result = value;
  if (!value.toLowerCase().startsWith('n')) {
    result = `N${value}`;
  }
  return result.toUpperCase();
};

import React from 'react';

const RightIcon = (props) => {
  const {
    isDisable,
  } = props;

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.0297 12.5301C17.0995 12.4604 17.1549 12.3776 17.1927 12.2865C17.2305 12.1954 17.25 12.0977 17.25 11.9991C17.25 11.9004 17.2305 11.8027 17.1927 11.7116C17.1549 11.6205 17.0995 11.5377 17.0297 11.4681L8.02966 2.46805C7.88883 2.32722 7.69782 2.2481 7.49866 2.2481C7.2995 2.2481 7.10849 2.32722 6.96766 2.46805C6.82683 2.60888 6.74771 2.79989 6.74771 2.99905C6.74771 3.19822 6.82683 3.38922 6.96766 3.53005L15.4382 11.9991L6.96766 20.4681C6.82683 20.6089 6.74771 20.7999 6.74771 20.9991C6.74771 21.1982 6.82683 21.3892 6.96766 21.5301C7.10849 21.6709 7.2995 21.75 7.49866 21.75C7.69782 21.75 7.88883 21.6709 8.02966 21.5301L17.0297 12.5301Z"
        fill={isDisable ? '#B6B6B6' : '#E32922'}
      />
    </svg>
  );
};

export default RightIcon;

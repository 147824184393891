import React from 'react';
import { Form as FinalForm } from 'react-final-form';

import {
  FILTER_KEY_MAKER,
  FILTER_KEY_MODEL_FAMILY,
  FILTER_KEY_CATEGORY,
  FILTER_KEY_MODEL,
} from '../SearchPage/SearchPage.constants';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routing/routeConfiguration';

import SingleEngineIcon from './icons/SingleEngineIcon';
import MultiEngineIcon from './icons/MultiEngineIcon';
import TurbopropsIcon from './icons/TurbopropsIcon';
import JetIcon from './icons/JetIcon';
import HelicoptersIcon from './icons/HelicoptersIcon';
import { Form, PrimaryButton } from '../../components';
import SearchAsyncSelect from '../../components/Topbar/TopbarSearchForm/SearchAsyncSelect';

import css from './LandingSearchForm.module.scss';

const findCategories = (categories, label) => {
  const found = categories.filter(i => i.label.includes(label));
  return found.map(i => i.value);
};

export const AircraftLandingSearchForm = (props) => {
  const {
    isMainSearchTypeKeywords,
    intl,
    isMobileLayout,
    categories,
    history,
    getLandingSearchMakeModelFamilyPromise,
  } = props;

  const filteredCategories = categories
    .reduce((acc, curr) => {
      return [...acc, ...curr.items];
    }, [])
  ;

  const sepCategories = findCategories(filteredCategories, 'Single');
  const mepCategories = findCategories(filteredCategories, 'Multi');
  const turboCategories = findCategories(filteredCategories, 'Turboprop');
  const jetsCategories = findCategories(filteredCategories, 'Jet');
  const helicopterCategories = findCategories(filteredCategories, 'Helicopter');

  const redirectToSearchPage = searchParams => {
    history.push(createResourceLocatorString(
      'AircraftSearchPage',
      routeConfiguration(),
      {},
      searchParams
    ));
  };

  const handleSEPClick = () => {
    redirectToSearchPage({ [FILTER_KEY_CATEGORY]: sepCategories.toString() });
  }

  const handleMEPClick = () => {
    redirectToSearchPage({ [FILTER_KEY_CATEGORY]: mepCategories.toString() });
  };

  const handleTurbopropsClick = () => {
    redirectToSearchPage({ [FILTER_KEY_CATEGORY]: turboCategories.toString() });
  };

  const handleJetsClick = () => {
    redirectToSearchPage({ [FILTER_KEY_CATEGORY]: jetsCategories.toString() });
  };

  const handleHelicoptersClick = () => {
    redirectToSearchPage({ [FILTER_KEY_CATEGORY]: helicopterCategories.toString() });
  };

  const getOptionsResponse = search => {
    return getLandingSearchMakeModelFamilyPromise(search);
  }

  const handleSubmitForm = (values) => {
    if (isMainSearchTypeKeywords && values) {
      if (values.value === 'pub_keywords') {
        const params = !values.makerId
          ? { pub_keywords: values.rawLabel }
          : {
            [FILTER_KEY_MAKER]: values.makerId,
            [FILTER_KEY_MODEL_FAMILY]: values.modelFamilyName,
          }
        redirectToSearchPage(params);
      } else {
        const params = {
          [FILTER_KEY_MAKER]: values.makerId,
          [FILTER_KEY_MODEL_FAMILY]: values.modelFamilyName,
          ...(values.modelId && { [FILTER_KEY_MODEL]: values.modelId }),
        };
        redirectToSearchPage(params)
      }
    }
  }

  return (
    <>
      <div className={css.categoriesWrapper}>
        <div className={css.firstRow}>
          <div
            className={css.categoryItem}
            onClick={handleSEPClick}
          >
            <SingleEngineIcon className={css.icon} />
            <span>Single Engine Piston</span>
          </div>
          <div
            className={css.categoryItem}
            onClick={handleMEPClick}
          >
            <MultiEngineIcon className={css.icon} />
            <span>Multi-Engine Piston</span>
          </div>
        </div>
        <div className={css.secondRow}>
          <div
            className={css.categoryItem}
            onClick={handleTurbopropsClick}
          >
            <TurbopropsIcon className={css.icon} />
            <span>Turboprops</span>
          </div>
          <div
            className={css.categoryItem}
            onClick={handleJetsClick}
          >
            <JetIcon className={css.icon} />
            <span>Jets</span>
          </div>
          <div
            className={css.categoryItem}
            onClick={handleHelicoptersClick}
          >
            <HelicoptersIcon className={css.icon} />
            <span>Helicopters</span>
          </div>
        </div>
      </div>

      <div className={css.orDelimiter}>{intl.formatMessage({ id: 'LandingSearchForm.or' })}</div>

      <FinalForm
        onSubmit={handleSubmitForm}
        initialValues={{ search: null }}
        render={formRenderProps => {
          const {
            handleSubmit,
          } = formRenderProps;

          return (
            <Form
              className={css.form}
              onSubmit={handleSubmit}
            >
              <SearchAsyncSelect
                applyFocusStyles
                placeholder='Search Make and Model'
                iconClassName={css.searchIcon}
                iconColorClassName={css.searchIconColor}
                isHeaderSearch={false}
                isMobile={isMobileLayout}
                shouldClearQuickSearch={true}
                keywordSearchWrapperClasses={css.searchField}
                onGetMakeModelPromise={getOptionsResponse}
                onChange={handleSubmitForm}
              />

              {/*<PrimaryButton*/}
              {/*  className={css.submitButton}*/}
              {/*>*/}
              {/*  {intl.formatMessage({ id: 'RealEstateLandingSearchForm.searchButton' })}*/}
              {/*</PrimaryButton>*/}
            </Form>
          );
        }}
      />
    </>
  );
};

import get from 'lodash/get.js';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import routeConfiguration from '../../../routing/routeConfiguration.js';
import { FormattedMessage } from '../../../util/reactIntl';
import {
  Button,
  ListingCard,
  NamedLink,
} from '../../../components';
import { createResourceLocatorString } from '../../../util/routes.js';
import { FILTER_KEY_PRICE_CHANGED } from '../../SearchPage/SearchPage.constants.js';
import css from './SectionPriceChange.module.scss';

const SectionPriceChange = props => {
  const {
    airplanesMetadata,
    rootClassName,
    className,
    changePriceListings,
    currentUser,
    onManageDisableScrolling,
  } = props;

  const cardRenderSizes = isMapVariant => {
    if (isMapVariant) {
      // Panel width relative to the viewport
      const panelMediumWidth = 50;
      const panelLargeWidth = 62.5;
      return [
        '(max-width: 767px) 100vw',
        `(max-width: 1023px) ${panelMediumWidth}vw`,
        `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
        `${panelLargeWidth / 3}vw`,
      ].join(', ');
    } else {
      // Panel width relative to the viewport
      return [
        '(max-width: 549px) 100vw',
        '(max-width: 767px) 50vw',
        `(max-width: 1439px) 26vw`,
        `(max-width: 1920px) 18vw`,
        `14vw`,
      ].join(', ');
    }
  };

  if (!changePriceListings?.length) {
    return null;
  }

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <h2 className={css.title}>
        <FormattedMessage id="SectionPriceChange.title" />
      </h2>
      <div className={css.listingsWrapper}>
        {changePriceListings.map(l => (
          <ListingCard
            airplaneMetadata={airplanesMetadata.value[l.id.uuid]}
            className={css.listingCard}
            key={l.id.uuid}
            listing={l}
            renderSizes={cardRenderSizes(true)}
            // isPremium
            priceChanged={l.attributes.publicData.price_changed}
            currentUser={currentUser}
            onManageDisableScrolling={onManageDisableScrolling}
          />
        ))}
      </div>
      <NamedLink
        name="AircraftSearchPage"
        className={css.viewAllButton}
        to={{search: `?${FILTER_KEY_PRICE_CHANGED}=drops%2Cincrease`}}
      >
        <FormattedMessage id="SectionPriceChange.button" />
      </NamedLink>
    </div>
  );
};

SectionPriceChange.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionPriceChange.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionPriceChange;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage } from '../../../util/reactIntl';
import {
  FILTER_KEY_ESTIMATE_EVALUATION_TEXT,
} from '../../SearchPage/SearchPage.constants';

import { ListingCard, NamedLink } from '../../../components';

import css from './SectionGoodDealListings.module.css';

const SectionGoodDealListings = props => {
  const {
    airplanesMetadata,
    rootClassName,
    className,
    goodDealListings,
    currentUser,
    onManageDisableScrolling,
  } = props;

  const cardRenderSizes = isMapVariant => {
    if (isMapVariant) {
      // Panel width relative to the viewport
      const panelMediumWidth = 50;
      const panelLargeWidth = 62.5;
      return [
        '(max-width: 767px) 100vw',
        `(max-width: 1023px) ${panelMediumWidth}vw`,
        `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
        `${panelLargeWidth / 3}vw`,
      ].join(', ');
    } else {
      // Panel width relative to the viewport
      const panelMediumWidth = 50;
      const panelLargeWidth = 62.5;
      return [
        '(max-width: 549px) 100vw',
        '(max-width: 767px) 50vw',
        `(max-width: 1439px) 26vw`,
        `(max-width: 1920px) 18vw`,
        `14vw`,
      ].join(', ');
    }
  };

  if (!goodDealListings?.length) {
    return null;
  }

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <h2 className={css.title}>Check Out These PlanePrice Deals</h2>
      <div className={css.listingsWrapper}>
        {goodDealListings.map(l => (
          <ListingCard
            airplaneMetadata={airplanesMetadata.value[l.id.uuid]}
            className={css.listingCard}
            key={l.id.uuid}
            listing={l}
            renderSizes={cardRenderSizes(true)}
            // isPremium
            currentUser={currentUser}
            onManageDisableScrolling={onManageDisableScrolling}
          />
        ))}
      </div>
      <NamedLink
        name="AircraftSearchPage"
        className={css.viewAllButton}
        to={{search: `?${FILTER_KEY_ESTIMATE_EVALUATION_TEXT}=Great Deal,Good Deal`}}
      >
        <FormattedMessage id="SectionFeaturedListings.button" />
      </NamedLink>
    </div>
  );
};

SectionGoodDealListings.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

SectionGoodDealListings.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionGoodDealListings;

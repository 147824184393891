import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from '../../util/reactIntl';
import classnames from 'classnames';

import {
  airplanesService,
  landingSearchFormService,
} from '../../services/services';
import config from '../../config';
import { isMainSearchTypeKeywords } from '../../util/search.js';
import {
  LISTING_TYPE__AIRPLANES,
  LISTING_TYPE__REAL_ESTATE,
} from '../../util/editListingHelpers';

import { AircraftLandingSearchForm } from './AircraftLandingSearchForm';
import { RealEstateLandingSearchForm } from './RealEstateLandingSearchForm';

import css from './LandingSearchForm.module.scss';

const MODAL_BREAKPOINT = 768;

const LandingSearchForm = (props) => {
  const {
    intl,
    airplaneCategories,
    history,
    listingType,
    onGetMakeModelPromise,
  } = props;

  const isWindowDefined = typeof window !== 'undefined';
  const isMobileLayout = isWindowDefined && window.innerWidth < MODAL_BREAKPOINT;
  const isAircraftForm = listingType === LISTING_TYPE__AIRPLANES;
  const isRealEstateForm = listingType === LISTING_TYPE__REAL_ESTATE;

  React.useEffect(() => {
    landingSearchFormService.init();
    return () => landingSearchFormService.resetState();
  }, []);

  const handleAirTypeClick = () => {
    landingSearchFormService.selectListingType(LISTING_TYPE__AIRPLANES);
  };

  const handleRETypeClick = () => {
    landingSearchFormService.selectListingType(LISTING_TYPE__REAL_ESTATE);
  };

  const form = isAircraftForm ? (
    <AircraftLandingSearchForm
      intl={intl}
      isMobileLayout={isMobileLayout}
      categories={airplaneCategories}
      history={history}
      getLandingSearchMakeModelFamilyPromise={onGetMakeModelPromise}
      isMainSearchTypeKeywords={isMainSearchTypeKeywords(config)}
    />
  ) : (
    <RealEstateLandingSearchForm
      intl={intl}
      isMobileLayout={isMobileLayout}
      history={history}
    />
  );

  return (
    <div className={css.root}>
      <div className={css.typeSwitch}>
        <div
          className={classnames(css.listingTypeButton, css.buttonAir, {
            [css.active]: isAircraftForm,
          })}
          onClick={handleAirTypeClick}
        >
          {intl.formatMessage({ id: 'LandingSearchForm.forAircraft' })}
        </div>
        <div
          className={classnames(css.listingTypeButton, css.buttonRE, {
            [css.active]: isRealEstateForm,
          })}
          onClick={handleRETypeClick}
        >
          {intl.formatMessage({ id: 'LandingSearchForm.forRealEstate' })}
        </div>
      </div>
      <div className={css.line} />
      {form}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    listingType: landingSearchFormService.$listingType(state),
    airplaneCategories: airplanesService.$categories(state),
  };
};

export default compose(
  injectIntl,
  withRouter,
  connect(mapStateToProps)
)(LandingSearchForm);

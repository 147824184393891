import React from 'react';
import classNames from 'classnames';
import {
  connect,
} from 'react-redux';
import {
  compose,
} from 'redux';
import {
  string,
} from 'prop-types';

import {
  FormattedMessage,
  injectIntl,
} from '../../util/reactIntl';
import {
  cardRenderSizes,
} from '../../util/listingCard';
import {
  $newestListingsState,
  fetchNewestListings,
  resetState,
} from './NewestListings.duck';
import {
  $airplanesMetadataField,
} from '../../modules/airplanes/airplanes.selectors';
import {
  manageDisableScrolling,
} from '../../ducks/UI.duck';

import {
  ListingCard,
  NamedLink,
} from '../../components';

import css from './NewestListings.module.scss';

const NewestListingsComponent = props => {
  const {
    airplanesMetadata,
    rootClassName,
    className,
    newestListingsState,
    currentUser,
    onManageDisableScrolling,
    onFetchListings,
    onResetState,
  } = props;

  React.useEffect(() => {
    onFetchListings();
    return () => onResetState();
  }, []);

  if (!newestListingsState.data.length) {
    return null;
  }

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <h2 className={css.title}>
        <FormattedMessage id="SectionFeaturedListings.title" />
      </h2>
      <div className={css.listingsWrapper}>
        {newestListingsState.data.map(l => (
          <ListingCard
            airplaneMetadata={airplanesMetadata.value[l.id.uuid]}
            className={css.listingCard}
            key={l.id.uuid}
            listing={l}
            renderSizes={cardRenderSizes(true)}
            currentUser={currentUser}
            onManageDisableScrolling={onManageDisableScrolling}
          />
        ))}
      </div>
      <NamedLink
        name="AircraftSearchPage"
        className={css.viewAllButton}
      >
        <FormattedMessage id="SectionFeaturedListings.button" />
      </NamedLink>
    </div>
  );
};

NewestListingsComponent.defaultProps = {
  rootClassName: null,
  className: null,
};

NewestListingsComponent.propTypes = {
  rootClassName: string,
  className: string,
};


const mapStateToProps = state => {
  return {
    newestListingsState: $newestListingsState(state),
    airplanesMetadata: $airplanesMetadataField(state),
    currentUser: state.user.currentUser,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onManageDisableScrolling: (componentId, disableScrolling) =>
      dispatch(manageDisableScrolling(componentId, disableScrolling)),
    onFetchListings: () => dispatch(fetchNewestListings()),
    onResetState: () => dispatch(resetState()),
  };
};

const NewestListings = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(NewestListingsComponent);

export default NewestListings;

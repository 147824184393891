import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
  FILTER_KEY_PRICE_CHANGED
} from '../../SearchPage/SearchPage.constants.js';

import {
  FormattedMessage,
} from '../../../util/reactIntl';
import {
  ListingCard,
  NamedLink,
} from '../../../components';

import css from './SectionNewestREListings.module.scss';

const { string } = PropTypes;

const SectionNewestREListings = props => {
  const {
    airplanesMetadata,
    rootClassName,
    className,
    listings,
    currentUser,
    onManageDisableScrolling,
  } = props;

  const cardRenderSizes = isMapVariant => {
    if (isMapVariant) {
      // Panel width relative to the viewport
      const panelMediumWidth = 50;
      const panelLargeWidth = 62.5;
      return [
        '(max-width: 767px) 100vw',
        `(max-width: 1023px) ${panelMediumWidth}vw`,
        `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
        `${panelLargeWidth / 3}vw`,
      ].join(', ');
    } else {
      // Panel width relative to the viewport
      return [
        '(max-width: 549px) 100vw',
        '(max-width: 767px) 50vw',
        `(max-width: 1439px) 26vw`,
        `(max-width: 1920px) 18vw`,
        `14vw`,
      ].join(', ');
    }
  };

  if (!listings?.length) {
    return null;
  }

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <h2 className={css.title}>
        <FormattedMessage id="SectionNewestREListings.title" />
      </h2>
      <div className={css.listingsWrapper}>
        {listings.map(l => (
          <ListingCard
            airplaneMetadata={airplanesMetadata.value[l.id.uuid]}
            className={css.listingCard}
            key={l.id.uuid}
            listing={l}
            renderSizes={cardRenderSizes(true)}
            // isPremium
            priceChanged={l.attributes.publicData.price_changed}
            currentUser={currentUser}
            onManageDisableScrolling={onManageDisableScrolling}
          />
        ))}
      </div>
      <NamedLink
        name="RealEstateSearchPage"
        className={css.viewAllButton}
      >
        <FormattedMessage id="SectionNewestREListings.button" />
      </NamedLink>
    </div>
  );
};

SectionNewestREListings.defaultProps = {
  rootClassName: null,
  className: null,
};

SectionNewestREListings.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionNewestREListings;

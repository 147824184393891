import React from 'react';

import SearchRegistrationForm
  from '../../../components/SearchRegistrationForm/SearchRegistrationForm';

import css from './SectionSearchByTailNumber.module.scss';

const SectionSearchByTailNumber = (props) => {
  const {
    history,
    intl
  } = props;

  return (
    <div className={css.sectionSearchByTailNumberWrapper}>
      <h2>
        {intl.formatMessage({ id: 'SectionSearchByTailNumber.title' })}
      </h2>
      <span className={css.desc}>
        {intl.formatMessage({ id: 'SectionSearchByTailNumber.desc' })}
      </span>
      <SearchRegistrationForm
        intl={intl}
        history={history}
        rootClassname={css.searchForm}
      />
    </div>
  );
};

export default SectionSearchByTailNumber;

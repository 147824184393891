import React from 'react';

const LeftIcon = (props) => {
  const {
    isDisable,
  } = props;

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.97034 12.5301C6.9005 12.4604 6.84508 12.3776 6.80727 12.2865C6.76946 12.1954 6.75 12.0977 6.75 11.9991C6.75 11.9004 6.76946 11.8027 6.80727 11.7116C6.84508 11.6205 6.9005 11.5377 6.97034 11.4681L15.9703 2.46805C16.1112 2.32722 16.3022 2.2481 16.5013 2.2481C16.7005 2.2481 16.8915 2.32722 17.0323 2.46805C17.1732 2.60888 17.2523 2.79989 17.2523 2.99905C17.2523 3.19822 17.1732 3.38922 17.0323 3.53005L8.56184 11.9991L17.0323 20.4681C17.1732 20.6089 17.2523 20.7999 17.2523 20.9991C17.2523 21.1982 17.1732 21.3892 17.0323 21.5301C16.8915 21.6709 16.7005 21.75 16.5013 21.75C16.3022 21.75 16.1112 21.6709 15.9703 21.5301L6.97034 12.5301Z"
        fill={isDisable ? '#B6B6B6' : '#E32922'}
      />
    </svg>
  );
};

export default LeftIcon;

import React from 'react';
import classnames from 'classnames';

import iphone from "./images/iphone15pro.png";
import { Button } from '../index';

import css from './SectionFindThePlanePrice.module.scss';

const SectionFindThePlanePrice = (props) => {
  const {
    rootClassName,
    buttonClassName,
    intl,
    onLearnMoreClick,
  } = props;

  return (
    <div className={classnames(css.root, rootClassName)}>
      <div className={css.text}>
        <h2>{intl.formatMessage({ id: 'SectionFindThePlanPrice.title' })}</h2>
        <p>{intl.formatMessage({ id: 'SectionFindThePlanPrice.text' })}</p>
        <Button
          className={classnames(css.learnMoreButton, buttonClassName)}
          onClick={onLearnMoreClick}
        >
          {intl.formatMessage({ id: 'SectionFindThePlanPrice.buttonText' })}
        </Button>
      </div>
      <div className={css.image}>
        <img src={iphone} alt="iphone 15 pro" />
      </div>
    </div>
  );
};

export default SectionFindThePlanePrice;

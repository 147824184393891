import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
  FormattedMessage,
} from '../../../util/reactIntl';
import {
  FILTER_KEY_FEATURED,
} from '../../SearchPage/SearchPage.constants';

import {
  NamedLink,
} from '../../../components';
import DesktopListings from './DesktopListings';
import MobileListings from './MobileListings';

import css from './SectionSponsoredListings.module.scss';

const SectionSponsoredListings = props => {
  const {
    intl,
    isMobile,
    airplanesMetadata,
    rootClassName,
    className,
    listings,
    currentUser,
    onManageDisableScrolling,
  } = props;

  if (!listings?.length) {
    return null;
  }

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <h2 className={css.title}>{intl.formatMessage({ id: 'LandingPage.featuredListings.title' })}</h2>
      {isMobile ? (
        <MobileListings
          metadata={airplanesMetadata}
          listings={listings}
          currentUser={currentUser}
          onManageDisableScrolling={onManageDisableScrolling}
        />
      ) : (
        <DesktopListings
          metadata={airplanesMetadata}
          listings={listings}
          currentUser={currentUser}
          onManageDisableScrolling={onManageDisableScrolling}
        />
      )}
      <NamedLink
        name="AircraftSearchPage"
        className={css.viewAllButton}
      >
        <FormattedMessage id="SectionFeaturedListings.button" />
      </NamedLink>
    </div>
  );
};

SectionSponsoredListings.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

SectionSponsoredListings.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionSponsoredListings;

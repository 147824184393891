import React from 'react';
import get from 'lodash/get';
import { Form as FinalForm } from 'react-final-form';

import routeConfiguration from '../../routing/routeConfiguration';
import { makeRegistrationNumber } from '../../util/registrationPage.helpers';
import { createResourceLocatorString } from '../../util/routes';

import {
  Form, FieldTextInput, PrimaryButton,
} from '../../components';

import css from './SearchRegistrationForm.module.scss';

const SearchRegistrationForm = (props) => {
  const {
    intl,
    rootClassname,
    history,
  } = props;

  const handleSearchSubmit = values => {
    const val = get(values, 'search', null);
    if (!val) {
      return ;
    }
    history.push(createResourceLocatorString(
      'AircraftRegistrationPage',
      routeConfiguration(),
      { reg_no: makeRegistrationNumber(val) },
      {}
    ));
  };

  return (
    <div className={rootClassname || css.searchFormWrapper}>
      <FinalForm
        onSubmit={handleSearchSubmit}
        render={fieldProps => {
          const {
            handleSubmit,
            invalid,
          } = fieldProps;

          return (
            <Form
              className={css.searchForm}
              onSubmit={handleSubmit}
            >
              <FieldTextInput
                name='search'
                id='search'
                className={css.searchFormField}
                inputRootClass={css.inputField}
                isSearch
                type='text'
                placeholder={intl.formatMessage({ id: 'AircraftRegistrationPage.searchForm.placeholder' })}
              />

              <PrimaryButton
                className={css.searchFormButton}
                disabled={invalid}
              >
                {intl.formatMessage({ id: 'AircraftRegistrationPage.searchForm.search' })}
              </PrimaryButton>
            </Form>
          );
        }}
      />
    </div>
  );
};

export default SearchRegistrationForm;
